<template>
  <div :class="[`${isCarDescription ? 'row' : ''}`]">
    <div :class="[`${isCarDescription ? 'col-md-6 col-12' : ''}`]">
      <div class="wrapper" v-click-outside="textareaBlur">
        <FloatLabel :label="label" :optional="optional" :class="[`${isCarDescription ? 'car-description-label' : ''}`]">
          <div :class="[`${error ? 'invalid' : ''}`]" class="text-area-wrapper">
            <b-form-textarea
              id="textarea"
              class="shadow-none form-control text-area-custom custom-scrollbar"
              :name="name"
              no-resize
              spellcheck="false"
              :placeholder="!inputValue ? placeholder : ''"
              v-model="inputValue"
              @input="trimInputValue"
              :rows="rows"
              :class="[`${error ? 'invalid' : ''}`]"
              autocomplete="off"
              style="height: auto !important"
              @focus="textareaFocus"
              @blur="textareaBlur"
              ref="carDescription"
            ></b-form-textarea>
          </div>
          <!-- Custom rich-content placeholder -->
          <div
            v-if="!inputValue && isCarDescription"
            class="custom-placeholder"
          >
            <p>Don't forget to add:</p>
            <ul>
              <li><strong>Any damages</strong>, such as mechanical issues, scratches or dents</li>
              <li><strong>Extras</strong>, such as tint, sunroof</li>
            </ul>
          </div>
        </FloatLabel>
        <div class="fs-12 text-right mb-2 mt-n3 pr-2 err-text">
          {{ error ? helperText : "" }}
        </div>
      </div>
    </div>
    <b-col cols="12" md="6" v-if="inputValue && isCarDescription" class="car-description-banner">
      <Banner iconClass="reminder-icon">
        <p>Don't forget to add:</p>
        <ul>
          <li><strong>Any damages</strong>, such as mechanical issues, scratches or dents</li>
          <li><strong>Extras</strong>, such as tint, sunroof</li>
        </ul>
      </Banner>
    </b-col>
  </div>
</template>
  <script>
export default {
  components: {
    FloatLabel: () => import("./FloatLabel.vue"),
    Banner: () => import("../../components/photo-upload/Banner.vue"),
  },
  name: "CustomTextArea",
  inheritAttrs: false,
  model: {
    prop: "description",
    event: "change",
  },
  data() {
    return {
      inputValue: this.description,
      onFocus: false,
    };
  },
  props: {
    description: {
      type: [Number, String],
      default: () => "",
    },
    label: String,
    name: {
      type: String,
      default: () => "",
    },
    rows: {
      type: String,
      default: () => "5",
    },
    placeholder: {
      type: String,
      default: () => "",
    },
    error: Boolean,
    helperText: String,
    isFocus: Boolean,
    optional: Boolean,
    isCarDescription: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    textareaFocus() {
      this.onFocus = true;
    },
    textareaBlur() {
      this.onFocus = false;
      this.emitInputValue();
    },
    emitInputValue() {
      if (this.inputValue !== this.initTextPlaceholder) {
        this.$emit("change", this.inputValue);
      }
    },
    trimInputValue() {
      const cursorPos = this.$refs.carDescription.selectionStart;

      this.inputValue = this.inputValue.replace(/^\s+|\s+$/g, '');

      // Restore the cursor position
      this.$nextTick(() => {
        this.$refs.carDescription.setSelectionRange(cursorPos, cursorPos);
      });
    }
  },
};
</script>
  
  <style lang="scss">
$gray-color: #ced4da;
select.form-control.invalid,
input.form-control.invalid {
  border-color: red !important ;
}
.invalid {
  border-color: red !important;
}
.err-text {
  color: red;
  font-style: italic;
  min-height: 16px;
}
.text-area-wrapper {
  border: 1px solid $gray-color;
  border-radius: 8px;
  position: relative;

  .text-area-custom::placeholder {
    color: #989898 !important;
    font-weight: 600 !important;
  }

  .form-control {
    border: none !important;
    padding: 0.575rem 0.75rem;
    &.blank {
      color: #989898 !important;
    }
  }
}
.wrapper {
  position: relative;
}
.custom-placeholder {
  position: absolute;
  top: 16px; 
  left: 16px;
  z-index: 0;
  pointer-events: none;
  color: #989898 !important;
  line-height: 1.5;
  max-width: 408px;
  font-size: 15px;
  p {
    margin-bottom: 5px;
  }
  ul {
    padding-left: 20px;
    margin: 0;
    li {
      margin-bottom: 5px;
    }
    strong {
      font-family: 'Avenir Next LT W05 Bold';
    }
  }
}
.car-description-banner {
  margin-top: -28px;
}
.banner {
  align-items: flex-start !important;
}
.banner-content:has(p) {
  font-size: 16px;
  font-family: "Avenir Next LT W05 Regular";
  font-weight: 600;
  p {
    margin-bottom: 5px;
  }
  ul {
    padding-left: 20px;
    margin: 0;
    li {
      margin-bottom: 10px;
    }
    strong {
      font-family: 'Avenir Next LT W05 Bold';
    }
  }
}
@media screen and (min-width: 768px ) {
  .car-description-banner {
    margin-top: -20px;
  }
  .car-description-label span {
    top: -1.05em;
  }
  .banner:has(p) {
    padding: 13px;
  }
}
@media screen and (min-width: 991px ) {
  .car-description-label span {
    top: -.5em;
  }
  .car-description-banner {
    margin-top: 0;
    max-width: 506px;
  }
}
</style>